import React from 'react';
import { Popover } from 'antd';

export default function Popover2({
	title,
	message,
	content,
	children,
	overlayClassName = '',
	disabled,
	placement = 'bottom',
	getParent,
	...props
}) {
	return (
		<Popover
			{...props}
			overlayClassName={`popover2 ${overlayClassName}`}
			title={title}
			content={content || <div className="popover-message">{message}</div>}
			trigger={disabled ? 'none' : 'hover'}
			getPopupContainer={getParent || ((trigger) => trigger.parentElement)}
			placement={placement}
		>
			<span>{children}</span>
		</Popover>
	);
}
