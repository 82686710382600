import PodiumAvatar, { PodiumImage } from './PodiumAvatar';

import Link from './Link';
import Popover2 from './Popover2';
import Links from '../navigation/Links';
import ShoutoutIcon from '../../public/icons/shoutout-colored.svg';

export function ShoutoutRankCard({ rank, count, onClick }) {
	return (
		<div
			className={`rank-card${onClick ? ' clickable' : ''}`}
			onClick={onClick}
		>
			<div>
				<Popover2 content="Rank" placement={'top'}>
					<div style={{ cursor: 'pointer' }}>
						<PodiumImage
							podium={false}
							value={rank < 10 ? `0${rank}` : rank}
							image={<ShoutoutIcon />}
						/>
					</div>
				</Popover2>
				<span className="body-medium semibold">Shoutouts</span>
			</div>
			<Popover2 content={'# of shoutouts received'} placement={'top'}>
				<div style={{ cursor: 'pointer' }}>
					<p className="body-medium bold">{count < 10 ? `0${count}` : count}</p>
				</div>
			</Popover2>
		</div>
	);
}

export default function RankCard({ user, rank, count }) {
	return (
		<Link href={Links.User(user?.username)} className="rank-card clickable">
			<div>
				<Popover2 content={'Rank'} placement={'bottom'}>
					<div style={{ cursor: 'pointer' }}>
						<PodiumAvatar
							name={user.name || user.username}
							src={user.image}
							podium={false}
							value={rank < 10 ? `0${rank}` : rank}
							size={56}
						/>
					</div>
				</Popover2>
				<span className="body-medium semibold wrap-text">
					{user.name || user.username}
				</span>
			</div>
			<Popover2 content={'# of shoutouts received'} placement={'bottom'}>
				<div style={{ cursor: 'pointer' }}>
					<p className="body-medium bold">{count < 10 ? `0${count}` : count}</p>
				</div>
			</Popover2>
		</Link>
	);
}
