export default function SidebarCard({
	title,
	className = '',
	subtitle,
	...props
}) {
	return (
		<section className={`sidebar-card ${className}`}>
			{title && <h6 className="title">{title}</h6>}
			{subtitle && (
				<p className="semibold" style={{ marginBottom: 10 }}>
					{subtitle}
				</p>
			)}
			{props.children}
		</section>
	);
}
