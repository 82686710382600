import React from 'react';

export default function Grid({ render, items, minWidth, padding = 12 }) {
	return (
		<div className="Grid">
			<div
				className="Grid-section"
				style={{
					display: 'grid',
					gridTemplateColumns: `repeat(auto-fill, minmax(${minWidth}px, 1fr))`,
					gridGap: padding,
				}}
			>
				{items && items.map((item, index) => render(item, index))}
			</div>
		</div>
	);
}
