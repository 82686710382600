import React from 'react';

export default function Badge({
	children,
	shadow,
	border,
	color,
	background = 'white',
	className,
}) {
	return (
		<div
			className={`badge ${shadow && 'shadow'} ${
				border && 'bordered'
			} ${color} bg-${background} ${className}`}
		>
			{children}
		</div>
	);
}
