import { notification } from 'antd';

const DEFAULT_DURATION = 5;

const config = {
	placement: 'bottomLeft',
};

export default class Notification {
	static info = (message, { title = '', duration = DEFAULT_DURATION } = {}) =>
		notification.info({
			...config,
			message: title,
			description: message,
			icon: null,
			duration,
		});

	static error = (message, { title = '', duration = DEFAULT_DURATION } = {}) =>
		notification.error({
			...config,
			message: title,
			description: message,
			icon: null,
			duration,
		});

	static success = (
		message,
		{ title = '', duration = DEFAULT_DURATION } = {}
	) =>
		notification.success({
			...config,
			message: title,
			description: message,
			icon: null,
			duration,
		});

	static warn = (message, { title = '', duration = DEFAULT_DURATION } = {}) =>
		notification.warn({
			...config,
			message: title,
			description: message,
			icon: null,
			duration,
		});
}
