import { HtmlButton } from '../HtmlButton';
import Util from '../../util';
import SidebarCard from '../SidebarCard';
import Notification from '../../util/Notification';
import Analytics from '../../data/Analytics';

export default function ShareLinkCard({ message, link }) {
	const copyToClipboard = () => {
		Util.copyToClipboard(`https://${link}`);
		Notification.info('Copied to clipboard', { duration: 1 });

		Analytics.event(Analytics.Event.SHARE_LINK, {
			event: 'copy',
			place: 'card',
			link: link,
		});
	};

	return (
		<SidebarCard className="simple share-link-card">
			<img src="/icons/share.svg" alt="share" />
			<div>
				<p className="share-link-message">{message}</p>
				<div className="share-link">
					<span>{link}</span>

					<HtmlButton color="white" size="small" onClick={copyToClipboard}>
						Copy
					</HtmlButton>
				</div>
			</div>
		</SidebarCard>
	);
}
