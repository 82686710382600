import React from 'react';
import { Modal } from 'antd';
import Spinner from './Spinner';
import { X } from 'react-feather';

export default function Dialog({
	visible,
	title,
	hide,
	size,
	reset = true,
	children,
	closable = true,
	escape = false,
	className,
	footer,
	loading,
	showCloseIcon = false,
}) {
	return (
		<Modal
			title={title}
			visible={visible}
			onCancel={hide}
			footer={footer || null}
			closeIcon={
				showCloseIcon ? (
					<X onClick={hide} className="dialog-close-button" />
				) : null
			}
			centered
			wrapClassName={
				(size ? `modal-${size}` : '') +
				(showCloseIcon ? '' : ' has-header') +
				(className ? ` ${className}` : '')
			}
			destroyOnClose={reset}
			maskClosable={closable}
			keyboard={escape}
		>
			{loading ? <Spinner size="large" /> : children}
		</Modal>
	);
}
