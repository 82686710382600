import Avatar from './Avatar';
import Badge from './Badge';

function PodiumBadge({ podium, value }) {
	return (
		<Badge shadow={!podium} className={podium ? 'podium' : ''}>
			{value}
		</Badge>
	);
}

export function PodiumImage({ image, podium, value }) {
	return (
		<div className="podium-avatar">
			<div className="podium-avatar-image">{image}</div>
			<PodiumBadge podium={podium} value={value} />
		</div>
	);
}

export default function PodiumAvatar({ src, name, podium, value, size }) {
	return (
		<div className="podium-avatar">
			<Avatar src={src} name={name} size={size} />
			<PodiumBadge podium={podium} value={value} />
		</div>
	);
}
